import React from 'react'
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom'
import Receipt from './receipt'
import LandingPage from './tools/components/landing-page'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        {/* <Route
          path="/"
          element={
            <Link
              to={'/egcr/Receipt'}
              className="bg-black text-white rounded-md py-2 px-3"
            >
              Load Receipt
            </Link>
          }
        /> */}

        <Route path="/egcr/Receipt" element={<Receipt />} />
      </Routes>
    </Router>
  )
}

export default App
