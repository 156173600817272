import EGCRProvider from '../../context/egcr-context'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()
const Providers = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <EGCRProvider>{children}</EGCRProvider>
    </QueryClientProvider>
  )
}

export default Providers
