import React from 'react'
import { Link } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa'

function ErrorPage() {
  return (
    <div className="flex flex-col  items-center justify-center h-screen space-y-6">
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        An error occurred
      </h1>
      <p className="text-gray-600">But it is not your fault, it is from us.</p>
      <div className="flex">
        <a
          href="/egcr/Receipt"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          //   to={'/egcr/Receipt'}
        >
          Go back home
        </a>
        <button
          className="rounded-md px-3.5 py-2.5 text-sm font-semibold flex"
          to={'/egcr/Receipt'}
        >
          Contact support <FaArrowRight className="mt-1 ml-1" />
        </button>
      </div>
    </div>
  )
}

export default ErrorPage
