import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function LandingPage() {
  const [billNumber, setBillNumber] = useState('')
  const navigate = useNavigate()

  const handleChange = (event) => {
    setBillNumber(event.target.value)
  }
  const convertToHexadecimal = (e) => {
    e.preventDefault()
    const encodedData = btoa(`number=${billNumber.toUpperCase()}`)
    console.log(encodedData)
    navigate(`/egcr/Receipt?q=${encodedData}`)
  }
  return (
    <div
      className="relative overflow-hidden bg-cover bg-no-repeat  text-center h-screen "
      style={{ backgroundImage: `url('backgroundImage.png')` }}
    >
      <div className="bg-black w-screen h-screen opacity-75"> </div>
      <div className="flex absolute inset-0 flex-col justify-center items-center text-white">
        <form className="flex flex-col backdrop-blur-sm bg-white/30 w-[50%] h-[50%] justify-center items-center p-20 space-y-6 rounded-lg">
          <label className=" text-3xl text-white font-bold">EGCR Number</label>
          <input
            onChange={handleChange}
            type="text"
            placeholder="Enter bill number"
            className="w-full p-2 rounded-sm bg-transparent outline outline-1 uppercase"
          />
          <button
            onClick={convertToHexadecimal}
            type="submit"
            className="bg-blue-900 w-full p-2 rounded"
          >
            GENERATE EGCR
          </button>
        </form>
      </div>
    </div>
  )
}

export default LandingPage
