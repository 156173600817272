import { createContext, useContext, useState } from 'react'
import { request } from './request'
import { DVLA_EGCR_API_KEY, EGCR_API_KEY } from '../constants/endpoints'
import axios from 'axios'

const EGCRContext = createContext(undefined)
const EGCRProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)

  const fetchEgcr = (egcr) => {
    const decoded = egcr.replace('&org=dvla', '')
    return request(decoded, 'GET', null, setLoading)
  }

  // number=657657657adff&org=dvla

  const getEgcrLink = async (egcrLink, decodedNumber) => {
    console.log(egcrLink, decodedNumber)
    setLoading(true)
    const containsDvla = decodedNumber.toLowerCase().includes('dvla')
    return await fetch(`${egcrLink}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': containsDvla ? DVLA_EGCR_API_KEY : EGCR_API_KEY,
      },
    })
      .then(async (res) => {
        console.log(res)
        const egcrData = await res.blob()
        if (!!egcrData) {
          const url = URL.createObjectURL(egcrData)
          window.open(url, '_self')
        } else {
          console.log('Could not get file')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const [data, setData] = useState([])
  const url =
    'https://staging-egcr.digitalpayment.online/egcr/Receipt?q=bnVtYmVyPUFETUEwMDAwMDAwMDAwMDAwMTI2'

  const configs = {
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': EGCR_API_KEY,
    },
  }

  const getDvlaEgcr = () => {
    try {
      axios.get(url, configs).then((response) => {
        console.log(response)
      })
      // console.log('Data fetched successfully:', response)
    } catch (err) {
      console.log(err)
    }
  }

  const getEgcr = () => {
    setLoading(true)
    console.log(data)
    return axios.get(url).then((response) => setData(response.data))
  }

  return (
    <EGCRContext.Provider
      value={{
        loading,
        fetchEgcr,
        getEgcrLink,
        getDvlaEgcr,
        getEgcr,
      }}
    >
      {children}
    </EGCRContext.Provider>
  )
}

export const useEGCRContext = () => useContext(EGCRContext)
export default EGCRProvider
