import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import EGCRProvider from './context/egcr-context'
import Providers from './tools/components/providers'
import ErrorBoundary from './tools/components/error-boundary'
import ErrorPage from './tools/components/error-page'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<ErrorPage />}>
      <Providers>
        <App />
      </Providers>
    </ErrorBoundary>
  </React.StrictMode>,
)
